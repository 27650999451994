<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewTreatmentSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-treatment-handler-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 v-if="!treatmentLocal.id" class="mb-0">Neue Behandlung</h5>
        <h5 v-if="treatmentLocal.id" class="mb-0">Bearbeiten Behandlung</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Mitarbeiter -->
          <validation-provider
            #default="validationContext"
            name="Mitarbeiter"
            rules="required"
          >
            <b-form-group
              label="Mitarbeiter"
              label-for="mitarbeiter"
              :state="getValidationState(validationContext)"
            >
              <v-select
                ref="selectedMitarbeiter"
                v-model="treatmentLocal.mitarbeiter_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="mitarbeiters"
                label="emailadresse"
                :reduce="(val) => val.id"
                :clearable="false"
                input-id="mitarbeiter"
                :disabled="disableSelectingMitarbeiter()"
              >
                <template slot="selected-option" slot-scope="option">
                  <span>{{ option.nachname }} {{ option.vorname }}</span>
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.nachname }} {{ option.vorname }}
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Behandlungsdatum -->
          <validation-provider
                  #default="validationContext"
                  name="Behandlungsdatum"
                  rules="required"
                >
            <b-form-group label="Behandlungsdatum" label-for="behandlungsdatum">
              <datepicker
                name="behandlungsdatum"
                :format="'dd/MM/yyyy'"
                v-model="treatmentLocal.behandlungsdatum"
                :input-class="'form-control'"
                :class="{
                  'is-invalid': validationContext.errors.length > 0,
                  'is-valid':
                    getValidationState(validationContext) == true,
                }"
              ></datepicker>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Behandlung -->
          <validation-provider
            #default="validationContext"
            name="Category"
            rules="required"
          >
            <b-form-group
              label="Category"
              label-for="category"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="treatmentLocal.category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                :reduce="(val) => val.Name"
                label="Name"
                :clearable="false"
                input-id="category"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Behandlung -->
          <validation-provider
            #default="validationContext"
            name="Behandlung"
            rules="required"
          >
            <b-form-group
              label="Behandlung"
              label-for="behandlung"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="treatmentLocal.behandlung"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :filter="fuseSearch"
                :options="services"
                :reduce="(val) => val.ID"
                label="ServiceName"
                :clearable="false"
                input-id="behandlung"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Preis -->
          <validation-provider
            #default="validationContext"
            name="Preis"
            rules="required"
          >
            <b-form-group label="Preis (CHF)" label-for="preis">
              <b-form-input
                id="preis"
                v-model.number="treatmentLocal.preis"
                type="number"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="RabattMoney">
            <b-form-group label="Rabatt (CHF)" label-for="rabattMoney">
              <b-form-input
                id="rabattMoney"
                v-model.number="treatmentLocal.rabatt_money"
                type="number"
                min="0"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="RabattPercent"
          >
            <b-form-group label="Rabatt (%)" label-for="rabattPercent">
              <b-form-input
                id="rabattPercent"
                v-model.number="treatmentLocal.rabatt_percent"
                type="number"
                step="0.001"
                min="0"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Frequenz"
            v-bind:rules="{
              required: selectedCategory && selectedCategory.frequenz,
            }"
          >
            <b-form-group
              v-if="selectedCategory && selectedCategory.frequenz"
              label="Frequenz (Hz)"
              label-for="frequenz"
            >
              <b-form-input
                id="frequenz"
                v-model.number="treatmentLocal.frequenz"
                type="number"
                step="0.001"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Energie"
            v-bind:rules="{
              required: selectedCategory && selectedCategory.energie,
            }"
          >
            <b-form-group
              v-if="selectedCategory && selectedCategory.energie"
              label="Energie (mJ)"
              label-for="energie"
            >
              <b-form-input
                id="energie"
                v-model.number="treatmentLocal.energie"
                type="number"
                step="0.001"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Durchmesser"
            v-bind:rules="{
              required: selectedCategory && selectedCategory.durchmesser,
            }"
          >
            <b-form-group
              v-if="selectedCategory && selectedCategory.durchmesser"
              label="Durchmesser (mm)"
              label-for="durchmesser"
            >
              <b-form-input
                id="durchmesser"
                v-model.number="treatmentLocal.durchmesser"
                type="number"
                step="0.001"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Energiedichte"
            v-bind:rules="{
              required: selectedCategory && selectedCategory.energiedichte,
            }"
          >
            <b-form-group
              v-if="selectedCategory && selectedCategory.energiedichte"
              label="Energiedichte (j/cm2)"
              label-for="energiedichte"
            >
              <b-form-input
                id="energiedichte"
                v-model.number="treatmentLocal.energiedichte"
                type="number"
                step="0.001"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Hauttyp -->
          <validation-provider
            #default="validationContext"
            name="Hauttyp"
            v-bind:rules="{
              required: selectedCategory && selectedCategory.hauttyp,
            }"
          >
            <b-form-group
              v-if="selectedCategory && selectedCategory.hauttyp"
              label="Hauttyp"
              label-for="hauttyp"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="treatmentLocal.hauttyp"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="hauttyps"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="hauttyp"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="Bemerkung" label-for="bemerkung">
            <b-form-textarea
              id="bemerkung"
              v-model="treatmentLocal.bemerkung"
              rows="3"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import { ref, onUnmounted } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { toRefs, watch } from "@vue/composition-api";
import useTreatmentHandler from "./useTreatmentHandler";
import store from "@/store";
import serviceStoreModule from "../serviceStoreModule";
import _ from "lodash";
import Fuse from "fuse.js";
import { getUserData } from "@/auth/utils";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    Datepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewTreatmentSidebarActive",
    event: "update:is-treatment-handler-sidebar-active",
  },
  props: {
    isAddNewTreatmentSidebarActive: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    allServices: {
      type: Array,
      required: true,
    },
    mitarbeiters: {
      type: Array,
      required: true,
    },
    treatment: {
      type: Object,
      required: true,
    },
    clearTreatmentData: {
      type: Function,
      required: true,
    },
    hauttyps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  setup(props, { emit }) {
    const SERVICE_STORE_MODULE_NAME = "app-service";

    // Register module
    if (!store.hasModule(SERVICE_STORE_MODULE_NAME))
      store.registerModule(SERVICE_STORE_MODULE_NAME, serviceStoreModule);

    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(SERVICE_STORE_MODULE_NAME))
        store.unregisterModule(SERVICE_STORE_MODULE_NAME);
    });

    const blankCategory = {
      Name: "",
      frequenz: true,
      energie: true,
      energiedichte: true,
      hauttyp: true,
      durchmesser: false,
    };

    const selectedCategory = ref(JSON.parse(JSON.stringify(blankCategory)));

    const services = ref([]);

    const { treatmentLocal, resetTreatmentLocal, onSubmit } =
      useTreatmentHandler(toRefs(props), emit);

    const userData = getUserData();

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetTreatmentLocal, props.clearTreatmentData);

    watch(
      () => props.mitarbeiters,
      (mitarbeiters, oldmitarbeiters) => {
        if (_.some(mitarbeiters, ["id", userData.extras.mitarbeiter_id])) {
          treatmentLocal.value.mitarbeiter_id = userData.extras.mitarbeiter_id;
        }
      }
    );

    watch(
      () => treatmentLocal.value.category,
      (currentCategory, oldCategory) => {
        if (oldCategory !== "") {
          treatmentLocal.value.behandlung = "";
        }

        selectedCategory.value = _.filter(
          props.categories,
          (category) => category.Name == currentCategory
        )[0];

        store
          .dispatch("app-service/fetchServices", {
            category: currentCategory,
          })
          .then((response) => {
            services.value = response.data.services;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              services.value = [];
            }
          });
      }
    );

    watch(
      () => treatmentLocal.value.behandlung,
      (currentService, oldService) => {
        if (currentService !== "") {
          var service = _.filter(
            props.allServices,
            (service) => service.ID == currentService
          )[0];
          if (!treatmentLocal.value.id) {
            treatmentLocal.value.preis = parseFloat(service.Price);
          }
        }
      }
    );

    watch(
      () => treatmentLocal.value.rabatt_money,
      (currentRabattAmount, oldRabattAmount) => {
        if (currentRabattAmount !== null && currentRabattAmount > 0) {
          treatmentLocal.value.rabatt_percent = null;
        }
      }
    );

    watch(
      () => treatmentLocal.value.rabatt_percent,
      (currentRabattPercent, oldRabattPercent) => {
        if (currentRabattPercent !== null && currentRabattPercent > 0) {
          treatmentLocal.value.rabatt_money = null;
        }
      }
    );

    return {
      treatmentLocal,
      services,
      selectedCategory,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    };
  },
  methods: {
    checkEnergiedichteNum () {
            this.treatmentLocal.energiedichte = parseInt(this.treatmentLocal.energiedichte.toString().replace(/[^0-9]/g, ''));
        },
    disableSelectingMitarbeiter() {
      const userData = getUserData();
      if (userData.role == "admin") {
        return false;
      }

      return true;
    },
    fuseSearch(options, search) {
      var vm = this;
      var optionByCategory = _.filter(options, function (o) {
        return o.Category == vm.treatmentLocal.category;
      });
      const fuse = new Fuse(optionByCategory, {
        keys: ["ServiceName"],
        //shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    // fetchServices(search, loading) {
    //   loading(true);
    //   this.search(loading, search, this);
    // },
    // search: _.debounce((loading, search, vm) => {
    //   store
    //     .dispatch("app-service/fetchServices", {
    //       q: search,
    //       category: vm.treatmentLocal.category,
    //     })
    //     .then((res) => {
    //       vm.services = res.data.services;

    //       loading(false);
    //     });
    // }, 350),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
