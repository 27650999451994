<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        md="8"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ customerData.vorname }} {{ customerData.nachname }}
              </h4>
              <span class="card-text">{{ customerData.emailadresse }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{
                  name: 'customers-edit',
                  params: { id: customerData.id },
                }"
                variant="primary"
              >
                {{ $t("Edit") }}
              </b-button>
              <b-button variant="secondary" class="ml-1" @click="onExport()">
                {{ $t("Export PDF") }}
              </b-button>
              <b-button class="ml-1" variant="secondary" @click="onSignAgain()">
                <span class="text-nowrap">{{ $t("Sign again") }}</span>
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" md="4">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Geburtsdatum</span>
            </th>
            <td class="pb-50">
              {{
                moment(String(customerData.geburtsdatum)).format("DD.MM.YYYY")
              }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Registrationsdatum</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{
                moment(String(customerData.registrationsdatum)).format(
                  "DD.MM.YYYY"
                )
              }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Telefonnummer</span>
            </th>
            <td>
              {{ customerData.telefonnummer }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from "bootstrap-vue";
import moment from "moment";
import axios from "@axios";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
  },
  props: {
    customerData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
  },
  setup() {
    return {};
  },
  methods: {
    onSignAgain() {
      var vm = this;
      const userData = getUserData();

      let routeData = this.$router.resolve({
        name: "customers-sign",
        params: { id: vm.customerData.id, mitarbeiter_id: userData.extras.mitarbeiter_id },
      });

      const windowFeatures = "location=yes,scrollbars=yes,status=yes";
      window.open(routeData.href, "kunde", windowFeatures);
    },
    onExport() {
      var vm = this;

      axios({
        url: `/kunde/export/${vm.customerData.id}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        const [, filename] =
          response.headers["content-disposition"].split("filename=");
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>

<style>
</style>
